/* eslint-disable no-process-env */
import { connect, Form, useFormikContext, getIn } from 'formik'
import React from 'react'
import isEqual from 'react-fast-compare'

/*
 * Custom wrapper for Formik Form which allows us to set the form context
 * inside the formik render method.
*/

type ErrorProps = {
  onError: (...args: any[]) => any;
};

export const ErrorListener = ({ onError }: ErrorProps) => {
  const formik = useFormikContext()
  const [ touched, setTouched ] = React.useState(formik.touched)
  const [ submitCount, setSubmitCount ] = React.useState(formik.submitCount)

  React.useEffect(() => {
    if (
      (!formik.isValidating &&
        !formik.isSubmitting &&
        formik.errors &&
        formik.submitCount > submitCount) ||
        (formik.submitCount && !isEqual(formik.touched, touched))
    ) {
      onError(formik.errors, formik.touched)

      setTouched(formik.touched)
      setSubmitCount(formik.submitCount)
    }
  }, [
    formik.errors,
    formik.isSubmitting,
    formik.isValidating,
    formik.submitCount,
    onError,
    submitCount
  ])

  return null
}

class CustomForm extends React.Component<CustomFormProps> {
  el: React.RefObject<HTMLElement> = { current: null }

  constructor(props: any) {
    super(props)
    this.state = { validated: false }
  }

  componentDidUpdate(prevProps: any) {
    const { formik } = this.props
    let { onChange } = this.props.config
    if (onChange && !isEqual(prevProps.formik, formik)) {
      onChange = onChange.bind(this.props.config)
      const diff = Object.keys(formik.values)
        .filter(k => !isEqual(getIn(formik.values, k), getIn(prevProps.formik.values, k)))
      onChange(diff, formik)
    }
  }

  render() {
    const Component = this.props.component || Form
    return (
      <Component
        id={this.props.id}
        ref={(el: React.RefObject<HTMLElement>) => (this.el = el)}
        className={this.props.className}
        onSubmit={(e: React.SyntheticEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          e.bubbles = false
          this.props.formik.handleSubmit(e)
        }}
      >
        {this.props.children}
      </Component>
    )
  }
}

type CustomFormProps = {
  onChange?: (...args: any[]) => any,
  handleSubmit?: (...args: any[]) => any,
  form?: any,
  mode?: string,
  actions?: any,
  config?: any,
  modelname?: string,
  className?: string,
  id?: string,
  component?: React.ElementType,
  userid?: number,
  modelid?: number,
  autosave?: boolean,
  formik: any,
  render?: (...args: any[]) => any
}

export default connect(CustomForm)
