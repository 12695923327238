import React, { useEffect, useState } from 'react'
import { getIn } from 'formik'
import Cookies from 'js-cookie'
import axios from 'axios'
import SearchFormSelect from './selects/SearchFormSelect'
import { uniqueArray } from './utils/uniqueArray'
import { groupBy } from './utils/groupBy'
import { OptionType, Suburb } from './selects/FormSelect/FormSelect.types'


const api = (url: string) => `/ajax${url}`

const csrftoken = Cookies.get('csrftoken')
const initializeSearch = () => axios.post(api('/setup-search'), {}, { headers: { 'X-CSRFToken': csrftoken } }).then(resp => {
  return resp.data
}).catch(e => console.error(e))

const LocationSelect = (props: any): JSX.Element => {

  const { values } = props.form

  const buy_rent = values.listing_type.includes("To Let") || values.listing_type.includes("Letting") ? "Rent" : "Buy"

  const [searchData, setSearchData] = useState(null)

  const [locationsResponse, setLocationsResponse] = useState<Suburb[]>([])

  const [areaOptions, setAreaOptions] = useState < OptionType<string>[]>([])

  const [ initialized, setInitialized ] = useState(false)

  useEffect(() => {
    const groupByCountry = groupBy(locationsResponse, 'country')

    const areaData: OptionType<string>[] = []
    Object.keys(groupByCountry).sort((a, b) => {
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }
      return 0
    }).map(country => {
      const groupByProvince = groupBy(groupByCountry[country], 'province')
      const provinceOptions = Object.keys(groupByProvince).sort().map(province => {
        const suburbs = groupByProvince[province].map((suburb: Suburb) => (
          {
            label: `${suburb.area}, ${suburb.suburb}`,
            value: `s_${suburb.id}`,
            area: `a_${suburb.area_id}`,
            province
          }
        ))
        const groupByArea = groupBy(groupByProvince[province], 'area')
        const areas = Object.keys(groupByArea).sort().filter(area => groupByArea[area].length > 1).map(area => {
          const areaSuburbs: Suburb[] = groupByArea[area]
          return {
            label: `${area}, All Suburbs`,
            value: `a_${areaSuburbs[0].area_id}`,
            area: `a_${areaSuburbs[0].area_id}`,
            province
          }
        })
        const allSuburbs = [...suburbs, ...areas]
        allSuburbs.sort((a, b) => {
          let A: string = a.label
          let B: string = b.label
          if (A.includes('All Suburbs')) {
            A = `${a.label.split(', ')[0]}`
          }
          if (B.includes('All Suburbs')) {
            B = `${b.label.split(', ')[0]}`
          }
          if (A < B) {
            return -1
          }
          if (A > B) {
            return 1
          }
          return 0
        })
        return {
          label: province,
          options: allSuburbs
        }
      })
      areaData.push({
        label: country,
        options: provinceOptions
      })
    })
    setAreaOptions(areaData)
  }, [locationsResponse])

  const findLocations = () => {
    if (buy_rent === 'Buy' && searchData) {

      Object.keys(searchData).forEach(key => {
        if ([
          'For Sale',
          'Residential Estate',
          'Residential Development',
          'Commercial Development'
        ].includes(key)) {
          if (getIn(searchData, `${key}.residential`) && values.listing_type.includes('Residential')) {
            const avalableLocations = getIn(searchData, `${key}.residential.property_types`).map((pt: { locations: Suburb; }) => pt.locations).reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])
            setLocationsResponse(avalableLocations)
          }
          if (getIn(searchData, `${key}.commercial`) && values.listing_type.includes('Commercial')) {
            const avalableLocations = getIn(searchData, `${key}.commercial.property_types`).filter((pt: { zoning: string[] }) => pt.zoning.includes('Commercial')).map((pt: { locations: Suburb; }) => pt.locations).reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])
            setLocationsResponse(avalableLocations)
          }
          if (getIn(searchData, `${key}.commercial`) && values.listing_type.includes('Retail')) {
            const avalableLocations = getIn(searchData, `${key}.commercial.property_types`).filter((pt: { zoning: string[] }) => pt.zoning.includes('Retail')).map((pt: { locations: Suburb; }) => pt.locations).reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])
            setLocationsResponse(avalableLocations)
          }
          if (getIn(searchData, `${key}.commercial`) && values.listing_type.includes('Industrial')) {
            const avalableLocations = getIn(searchData, `${key}.commercial.property_types`).filter((pt: { zoning: string[] }) => pt.zoning.includes('Industrial')).map((pt: { locations: Suburb; }) => pt.locations).reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])
            setLocationsResponse(avalableLocations)
          }
          if (getIn(searchData, `${key}.commercial`) && values.listing_type.includes('Retail')) {
            const avalableLocations = getIn(searchData, `${key}.commercial.property_types`).filter((pt: { zoning: string[] }) => pt.zoning.includes('Retail')).map((pt: { locations: Suburb; }) => pt.locations).reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])
            setLocationsResponse(avalableLocations)
          }
          if (getIn(searchData, `${key}.commercial`) && values.listing_type.includes('Agricultural')) {
            const avalableLocations = getIn(searchData, `${key}.commercial.property_types`).filter((pt: { zoning: string[] }) => pt.zoning.includes('Agricultural')).map((pt: { locations: Suburb; }) => pt.locations).reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])
            setLocationsResponse(avalableLocations)
          }
        }
      })
    } else if (searchData) {
      Object.keys(searchData).forEach(key => {
        if ([
          'To Let',
          'Holiday Letting'
        ].includes(key)) {
          if (getIn(searchData, `${key}.residential`) && values.listing_type.includes('Residential')) {
            const avalableLocations = getIn(searchData, `${key}.residential.property_types`).map((pt: { locations: Suburb; }) => pt.locations).reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])
            setLocationsResponse(avalableLocations)
          }
          if (getIn(searchData, `${key}.commercial`) && values.listing_type.includes('Commercial')) {
            const avalableLocations = getIn(searchData, `${key}.commercial.property_types`).filter((pt: { zoning: string[] }) => pt.zoning.includes('Commercial')).map((pt: { locations: Suburb; }) => pt.locations).reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])
            setLocationsResponse(avalableLocations)
          }
          if (getIn(searchData, `${key}.commercial`) && values.listing_type.includes('Retail')) {
            const avalableLocations = getIn(searchData, `${key}.commercial.property_types`).filter((pt: { zoning: string[] }) => pt.zoning.includes('Retail')).map((pt: { locations: Suburb; }) => pt.locations).reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])
            setLocationsResponse(avalableLocations)
          }
          if (getIn(searchData, `${key}.commercial`) && values.listing_type.includes('Industrial')) {
            const avalableLocations = getIn(searchData, `${key}.commercial.property_types`).filter((pt: { zoning: string[] }) => pt.zoning.includes('Industrial')).map((pt: { locations: Suburb; }) => pt.locations).reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])
            setLocationsResponse(avalableLocations)
          }
          if (getIn(searchData, `${key}.commercial`) && values.listing_type.includes('Retail')) {
            const avalableLocations = getIn(searchData, `${key}.commercial.property_types`).filter((pt: { zoning: string[] }) => pt.zoning.includes('Retail')).map((pt: { locations: Suburb; }) => pt.locations).reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])
            setLocationsResponse(avalableLocations)
          }
          if (getIn(searchData, `${key}.commercial`) && values.listing_type.includes('Agricultural')) {
            const avalableLocations = getIn(searchData, `${key}.commercial.property_types`).filter((pt: { zoning: string[] }) => pt.zoning.includes('Agricultural')).map((pt: { locations: Suburb; }) => pt.locations).reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])
            setLocationsResponse(avalableLocations)
          }
          if (getIn(searchData, `${key}.holiday`) && values.listing_type.includes('Holiday')) {
            const avalableLocations = getIn(searchData, `${key}.holiday.property_types`).map((pt: { locations: Suburb; }) => pt.locations).reduce((prevLocations: Suburb[], nextLocations: Suburb[]) => uniqueArray(prevLocations.concat(nextLocations), 'id'), [])
            setLocationsResponse(avalableLocations)
          }
        }
      })
    }
  }

  useEffect(() => {
    initializeSearch().then((data) => {
      setSearchData(data)
      setInitialized(true)
    })
  }, [ ])

  useEffect(() => {
    findLocations()
  }, [ initialized, buy_rent, searchData, values.listing_type ])
  return (
    <SearchFormSelect
      field={props.field.name}
      formikProps={props.form}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      placeholder="Type Area or Suburb Name"
      options={areaOptions}
      isClearable={false}
      isMulti
    />
  )
}

export default LocationSelect