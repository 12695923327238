/* eslint-disable new-cap */
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { NumericFormat } from 'react-number-format';

class LocalCurrencyInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = { val: '' }
  }

  componentDidMount() {
    if (this.props.field.value) { // Otherwise data loaded after mount does not show
      if (!this.state.val || this.state.val !== this.props.field.value && this.props.field.value) {
        this.setState({ val: this.props.field.value })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.field.value) { // Otherwise data loaded after mount does not show
      if (!this.state.val || this.state.val !== this.props.field.value) {
        this.setState({ val: this.props.field.value })
      }
    }
    if (!this.props.field.value && prevProps.field.value) {
      this.setState({ val: '' })
    }
  }

  render() {
    const { name } = this.props.field // { name, value, onChange, onBlur }
    const { handleBlur, setFieldTouched, setFieldValue } = this.props.form // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    return (
      <>
        <NumericFormat
          className={classNames('input-field')}
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator={' '}
          value={this.state.val}
          name={`${name}formatted`}
          placeholder={this.props.placeholder}
          onBlur={handleBlur}
          id={`${name}-formatted`}
          onClick={e => e.target.select()}
          onValueChange={values => {
            let { value } = values
            if (!value) { value = '' }
            if (this.state.val !== value) {
              this.setState({ val: value })
              setFieldTouched(name)
              setFieldValue(name, value)
            }
          } }
          allowNegative={false}
          valueIsNumericString
          type='tel'
        />

        <input
          id={name}
          name={name}
          type="hidden"
          defaultValue={this.state.val}
        />
      </>
    )
  }
}

LocalCurrencyInput.propTypes = {
  id: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
}

export default LocalCurrencyInput
