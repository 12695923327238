/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldInputProps, FormikProps } from 'formik'
import React from 'react'
import { CheckProps } from './Check.types'


export const handleCheck = (
  e: React.ChangeEvent<HTMLInputElement>,
  field: FieldInputProps<any>,
  form: FormikProps<any>,
  props: CheckProps
): React.ChangeEvent<HTMLInputElement> | null => {
  if (props.readonly) { return null }
  if (props.onChange) {
    props.onChange(e)
    return e
  }
  form.handleChange(e)
  form.handleBlur(e)
  return e
}

const Check = (props: CheckProps): JSX.Element => {
  let checked = props.field.value && props.field.value !== 'false' ? true : false
  if (props.isMulti) {
    checked = props.form.values[props.field.name].indexOf(props.value) !== -1
  }
  return (
    <div className="checkinput">
      <svg aria-hidden={true} style={{ position: 'absolute', width: 0, height: 0, overflow: 'hidden' }} viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <symbol id="icon16-Check-Small" viewBox="0 0 17 13">
            <path d="M6.64085 7.69738C6.58412 7.75378 6.49177 7.75378 6.43504 7.69738L2.61285 3.89996L0 6.49852L3.82144 10.2987L6.53836 13L17 2.59708L14.3849 0L6.64085 7.69738Z" />
          </symbol>
        </defs>
      </svg>
      <label className="checkcontainer">
        <div>
          <input
            id={props.id}
            className={checked ? 'on' : 'off'}
            checked={checked}
            {...props.field}
            onChange={e => handleCheck(e, props.field, props.form, props)}
            disabled={props.disabled}
            type="checkbox"
          />
          <span className={`checkmark ${props.field.name}`}>
            {checked ? <svg viewBox="0 0 17 13"><use href="#icon16-Check-Small" /></svg> : null}
          </span>
        </div>
      </label>

      {props.label ? <label htmlFor={props.id} dangerouslySetInnerHTML={{__html: props.label}} /> : null}
    </div>
  )
}

export default Check
