import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import FrontendForm from './FrontendForm'
import { FEFormConfig } from './interfaces'

declare global {
  interface Window {
      consentForm: FEFormConfig,
      request: any
      CAPTCHA_KEY: any
      log_ga_event: any
      initializeForms: any
  }
}

const consentForm = window.consentForm
if (typeof consentForm !== 'undefined') {
  try {
    const selector = document.querySelector(consentForm.selector)
    ReactDOM.render(
      <React.StrictMode>
        <FrontendForm config={consentForm} />
      </React.StrictMode>,
      selector
    )
  } catch (e) { console.error(e) }
}