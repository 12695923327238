import React from 'react'
import { FormikProps, getIn } from 'formik'
import { FEFormFieldConfig } from './interfaces'

export const getNodes = (str: string) => new DOMParser().parseFromString(str, 'text/html').body.childNodes

export const capitalize = (s: string) => {
  if (typeof s !== 'string') { return '' }
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const createJSX = (nodeArray: any, level = 0, includeType = false, replaceLineBreaks = true) => (

  nodeArray.map((node: any, idx: number) => {

    const {
      attributes,
      localName,
      childNodes,
      nodeValue,
      nodeType
    } = node

    const attributeObj: any = { }

    if (includeType) {
      attributeObj.nodeType = nodeType
    }

    if (nodeType === 3) {
      if (replaceLineBreaks) {
        return nodeValue.split('\n').map((line: string, i: number) => (
          <React.Fragment key={i}>
            {line}
            {/* <br /> */}
          </React.Fragment>
        ))
      }
      return nodeValue
    }

    if (nodeType === 8) {
      attributeObj.style = {}
      attributeObj.style.display = 'none'
      return React.createElement(
        'span',
        attributeObj,
        [ nodeValue ]
      )
    }

    if (attributes) {
      Array.from(attributes).forEach((attribute: any) => {
        if (attribute.name === 'style') {
          const styleAttributes = attribute.nodeValue.split(';')
          const styleObj: any = {}
          styleAttributes.forEach((attr: string) => {
            const [ key, value ] = attr.split(':')
            if (key.indexOf('-') !== -1) {
              const parts = key.split('-')
              const start = parts[0]
              const other = parts.slice(1, parts.length).map(p => capitalize(p)).join('')

              styleObj[`${start}${other}`] = value
            } else {
              styleObj[key] = value
            }
          })
          attributeObj[attribute.name] = styleObj
        } else if (attribute.name.indexOf('-') !== -1) {
          const parts = attribute.name.split('-')
          const start = parts[0]
          const other = parts.slice(1, parts.length).map((p: string) => capitalize(p)).join('')
          attributeObj[`${start}${other}`] = attribute.nodeValue
        } else {
          attributeObj[attribute.name] = attribute.nodeValue
        }
      })
      attributeObj.key = `${localName || 'node'}-${level}-${idx}`
    }

    const hasChildren = childNodes && Array.isArray(Array.from(childNodes)) && Array.from(childNodes).length

    if (localName) {
      return React.createElement(
        localName,
        attributeObj,
        hasChildren ? createJSX(Array.from(childNodes), level + 1, includeType, replaceLineBreaks) : null
      )
    }

    return nodeValue

  })

)


/*
 * Helper function to determine if a field should be applicable to the current selected context
 * , based on rules defined in the field config. ie. field.edit = Bool || Array
*/
export function isConditional(field: any, lookup: keyof FEFormFieldConfig, edit: boolean, form: FormikProps<any>) { // Need to use old school function here as we bind 'this'
  const { values, touched } = form
  const conditions = field[lookup]
  if (conditions) {
    if (Array.isArray(conditions)) {
      /*
       * Conditions is an array of rule objects. Each rule object can contain
       * multiple conditions. If all the conditions for a given rule are true,
       * then the response will be true, even if the additional rules are false.
       * Essentially: Rules are treated as OR statements, conditions are treated
       * as AND statements
      */
      const rules = conditions.map(rule => {
        const required = rule.length
        const passed = rule.map((condition: any) => {
          const fname = condition.field
          const fcondition = condition.condition
          let valid_rule = false
          let val = getIn(values, fname) || false
          const initialval = form ? getIn(form.initialValues, fname) : false
          switch (fcondition.type) {
            case 'min':
              if (!val) { val = [] }
              if (val.length >= fcondition.value) { valid_rule = true }
              break
            case 'max':
              if (!val) { val = [] }
              if (val.length <= fcondition.value) { valid_rule = true }
              break
            case 'count':
              if (!val) { val = [] }
              if (val.length === fcondition.value) { valid_rule = true }
              break
            case 'notcount':
              if (!val) { val = [] }
              if (val.length !== fcondition.value) { valid_rule = true }
              break
            case 'contains':
              if (Array.isArray(val)) {
                let vals
                if (fcondition.key) {
                  vals = val.map(v => fcondition.value === v[fcondition.key])
                } else {
                  vals = val.map(v => Array.isArray(fcondition.value) && fcondition.value.includes(v))
                }
                valid_rule = vals.some(v => v === true)
              } else {
                valid_rule = Array.isArray(fcondition.value) && fcondition.value.includes(val)
              }
              break
            case 'changed': { // Used for hiding or showing price reduced fields
              const old = form && form.initialValues ? getIn(form.initialValues, fname) : null
              switch (fcondition.comparison) {
                case 'lt':
                  valid_rule = parseFloat(val) < parseFloat(old)
                  break
                case 'lte':
                  valid_rule = parseFloat(val) <= parseFloat(old)
                  break
                case 'gt':
                  valid_rule = parseFloat(val) > parseFloat(old)
                  break
                case 'gte':
                  valid_rule = parseFloat(val) >= parseFloat(old)
                  break
                case 'ne':
                  valid_rule = val !== old
                  break
                default:
                  break
              }
              break
            }
            case 'exists':
              if (val || fcondition.value === val) { valid_rule = true }
              break
            case 'notexists':
              if (!val) { valid_rule = true }
              break
            case 'initialvalue':
              if (initialval) { valid_rule = true }
              break
            case 'touched':
              val = touched && touched[fname]
              if (val) { valid_rule = true }
              break
            case 'not':
              if (fcondition.value !== val) { valid_rule = true }
              break
            default: // Default to value of the named condition field
              if (fcondition.value === val) { valid_rule = true }
              break
          }
          return valid_rule
        })
        // This gives us our AND condition
        return required === passed.filter((val: any) => val === true).length
      })
      // This gives us our OR condition
      edit = rules.find(valid => valid === true) || false
    } else {
      edit = true
    }
  }
  return edit
}